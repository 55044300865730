@import '../App.scss';

#error-page {

    .error-custom-background {
        background-image: url('https://res.cloudinary.com/croner/image/upload/v1529001221/bg_second_red.gif');
        background-color: $croner-red;
        background-repeat: no-repeat;
        background-position: 70% 105px;
        background-attachment: fixed;
    }

    .error-header {
        height: 90px;
        color: #FFF;
        padding-top: 20px;
    }

    .error-custom-spacing {
        padding-top: 105px;    

        @media all and (max-width: 575px) {
            padding-top: 76px;    
            padding-left: 0;
            padding-right: 0;
        }
    }

    .error-sections {
        color: $dark-gray-font;
        
        .error-section-headers {
            background: -webkit-gradient(linear, left top, right bottom, from($croner-red), to(#FFFFFF));
            color: #FFFFFF;
        }

        a {
            color: $croner-red;
            font-weight: bold;
            font-size: 16px;
        }

        h4 {
            margin: 15px;
            font-size: 18px;
            font-weight: bold;
        }

        hr {
            margin-top: 0;
            margin-bottom: 0;
        }

        p {
            margin: 15px;
        }

        ul {
            margin-bottom: 0;
        }
    }
}
