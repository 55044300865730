// @import 'declarations';

#home {
  #title {
    height: 307px;
    width: 100%;
    background-image: url('https://croner.com/wp-content/uploads/2022/04/page-banner-news.jpg');
    // background-position: center -60px;
    // background-color: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
    background-size: cover;
    // background-size: 116%;
    background-position: center center;
    background-repeat: no-repeat;


    h1 {
      text-transform: uppercase;
      font-size: 55px;
      line-height: normal;
      font-weight: 800;
      letter-spacing: 3px;
      color: #fff;
      text-align: center;
      padding-top: 118px;
    }
  }

  #welcome,
  #my_surveys {
    margin: 60px 15%;

    h1 {
      text-transform: uppercase;
      font-size: 45px;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 1.5px;
      color: #151b2c;
      margin-bottom: 0;
    }

    h3 {
      color: #203c74;
      font-size: 28px;
      line-height: 1.05;
      font-weight: 500;
      letter-spacing: 0.2px;
    }

    h4 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 2px;
      text-align: center;
    }

    p,
    ul,
    li {
      font-size: 18px;
      color: #151b2c;
    }

    hr {
      max-width: 180px;
      height: 2px;
      background: #6d0f1d;
      margin-left: 0;
      margin-top: 22px;
      margin-bottom: 20px;
    }

    table {
      border: 0;
      td,
      th {
        border-style: hidden !important;
				border: 0;
        padding: 0;
        font-size: 18px;
      }

			ul {
				list-style: none;
				padding-left: 0;
				font-size: 18px;
			}

    }
  }

  // hr {
  // 	margin: 40px 80px 40px 80px;
  // }

  // #surveys {
  //   padding: 0px 80px 50px 80px;

  // 	h4 {
  // 		font-size: 30px;
  // 		text-transform: uppercase;
  // 		line-height: 1.05;
  // 		font-weight: 800;
  // 		letter-spacing: 2px;
  // 	}

  // 	hr {
  // 		max-width: 180px;
  // 		height: 1px;
  // 		background: #6d0f1d;
  // 		margin-left: 0;
  // 		margin-top: 22px;
  // 		margin-bottom: 20px;
  // 	}

  // 	h3 {
  //     color: #203c74;
  // 		font-size: 28px;
  // 		line-height: 1.05;
  //     font-weight: 500;
  // 		letter-spacing: .2px;
  // 		margin: 0;
  // 	}

  // 	td, th {
  // 		border-style: hidden !important;
  // 		padding: 0;
  // 		font-size: 18px;
  // 	}
  // }
}
