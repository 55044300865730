@import '../../App.scss';

#navigation {
  position: fixed;
  top: 0;
  box-shadow: rgb(0 0 0 / 12%) 0 1px 3px;
  width: 100%;
  z-index: 1;

  #top-bar {
    height: 6.1px;
    background-image: linear-gradient(to right, #203c74, #2f60a5);
  }

  nav {
    background-color: #fff;
    height: 99px;

    ul {
      justify-content: center;
      align-items: center;

      li {
        margin: 0 2px;

        a {
          text-transform: uppercase;
          color: #203c74;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;
          line-height: 1;
        }

        a:hover {
          text-transform: uppercase;
          color: #c92035;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;
          line-height: 1;
        }
      }
    }
  }
}

.collapse.show {
  width: 100% !important;
}

.navbar-toggler {
  border: none;
  font-size: 24px;
}

.navbar-toggler:focus-visible {
  border: none;
  font-size: 24px;
}

@mixin route-nav-dropdown-menu {
  display: none;
  position: absolute;
  // top: 65px;
  background-color: #fff;
  min-width: 275px;
  z-index: 9999;

  ul {
    list-style-type: none;
    padding: 0;
    padding-top: 35px;
    margin-top: 0;

    a {
      li {
        font-size: 12px;
        padding: 12px 20px;
        text-align: left;
        border-bottom: 1px solid #efefef;
        margin: 0 !important;
      }
    }
  }
}

%route-nav-dropdown-menu {
  @include route-nav-dropdown-menu;
}

nav {
  .nav-button {
    @media all and (max-width: 1199px) {
      background-color: #fff;
      margin: 0px !important;
    }
  }

  .custom-dropdown-menu {
    position: relative;
    display: inline-block;
    // padding-bottom: 18px;
    height: 100% !important;
    background-color: #fff;

    @media all and (max-width: 1199px) {
      padding-top: 0;
      width: 100%;
      border-bottom: 1px solid #efefef;

      ul {
        width: 100% !important;
      }

      .nav-dropdown-menu {
        display: none;
      }

      &:hover .nav-dropdown-menu {
        display: none;
      }
    }

    @media all and (min-width: 1199px) {
      .nav-dropdown-menu {
        @include route-nav-dropdown-menu;
      }

      &:hover .nav-dropdown-menu {
        display: block !important;
      }

      .sub-dropdown-menu {
        display: none;
        position: absolute;
        left: 275px;
        width: 100%;
        background-color: #fff;
      }
      .sub-dropdown-menu-parent:hover + .sub-dropdown-menu,
      .sub-dropdown-menu:hover {
        display: block !important;
      }
    }
  }
}
