@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');

$background: #7E8083;
$croner-blue: #1D428A;
$croner-red: #93272C;
$croner-purple: #642667;
$croner-green: #24985B;
$croner-yellow: #FFB010;
$dark-bg-font: #EDEDED;
$dark-bg-hover: #55A7E2;
$link-color: #7E8083;
$small-font: 11px;
$white-bg-font-color: #555;
$wrong-red: #B40103;
$dark-gray-font: #5f5f60;

body,
html {
  // position: relative;
  min-height: 100vh;
  height: 100%;
  font-family: 'Raleway';
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #c92035;
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

a:hover {
  text-decoration: none;
  color: #151b2c;
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.row {
  margin: 0;
}

.red-button {
  font-weight: 600;
  padding: 15px 24px;
  letter-spacing: 1.5px;
  background: #6d0f1d;
  background-image: linear-gradient(25deg, #6d0f1d 0%, #c92035 100%);
  border-color: rgba(201, 32, 53, 0);
  border-width: 0;
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: uppercase;
  border-radius: 0 !important;
  outline: none;
}

.red-button:focus {
  font-weight: 600;
  padding: 15px 24px;
  letter-spacing: 1.5px;
  background: #6d0f1d;
  background-image: linear-gradient(25deg, #4f0007 0%, #9e1717 100%);

  border-color: rgba(201, 32, 53, 0);
  border-width: 0;
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: uppercase;
  border-radius: 0 !important;
  outline: none;
}

.red-button:hover {
  background-image: linear-gradient(25deg, #4f0007 0%, #9e1717 100%);
}

.blue-button {
  font-weight: 600;
  padding: 15px 24px;
  letter-spacing: 1.5px;
  background: #001d75;
  background-image: linear-gradient(25deg, #203c74 25%, #2f60a5 100%);
  border-color: rgba(201, 32, 53, 0);
  border-width: 0;
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: uppercase;
  border-radius: 0 !important;
  outline: none;
}

.blue-button:focus {
  font-weight: 600;
  padding: 15px 24px;
  letter-spacing: 1.5px;
  background: #001d75;
  background-image: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
  border-color: rgba(201, 32, 53, 0);
  border-width: 0;
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: uppercase;
  border-radius: 0 !important;
  outline: none;
}

.blue-button:hover {
  background-image: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
}

.btn-primary {
  // background-image: linear-gradient(25deg, #001d75 25%, #2f60a5 100%) !important;
  background-color: $croner-blue;
  border-color: none;
}

.btn-primary:hover {
  // background-image: linear-gradient(25deg, #001d75 25%, #2f60a5 100%) !important;
  background-color: $croner-blue;
  border-color: none;
}

.btn-danger {
  // background-image: linear-gradient(25deg, #6d0f1d 0%, #c92035 100%);
  background-color: $croner-red;
  border-color: none;
}

.btn-danger:hover {
  // background-image: linear-gradient(25deg, #6d0f1d 0%, #c92035 100%);
  background-color: $croner-red;
  border-color: none;
}

#products {
  text-align: center;
  max-width: 1300px;
  margin: 60px auto;

  // margin-left: auto;
  // margin-right: auto;

  @media (max-width: 1200px) {
    #product-buttons-lg {
      display: none;
    }
  }

  @media (min-width: 1200px) {
    // margin: 35px 15%;

    .sm {
      display: none;
    }
  }
  .col-xl-4 {
    padding: 10px 50px;

    // max-width: 420px;

    h1 {
      text-transform: uppercase;
      font-size: 32px;
      line-height: 1.05;
      color: #151b2c;
      font-weight: 800;
      letter-spacing: 3px;
      margin-top: 12px;
    }

    h3 {
      font-size: 22px;
      line-height: 1.05;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #203c74;
      margin-top: -5px;
      padding-left: 32px;
      padding-right: 32px;
    }

    hr {
      width: 140px;
      height: 2px;
      background: #6d0f1d;
    }

    p {
      font-size: 18px;
      text-align: left !important;
      margin-bottom: 6px;
      margin-left: 38px;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
      padding-top: 4px;
    }
  }
}

.modal-content {
  border-radius: 0 !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  background-color: #fff;
  font-family: 'Raleway' !important;
}
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  background-color: #fff;
  font-family: 'Raleway' !important;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  background-color: #fff;
  font-family: 'Raleway' !important;
}
