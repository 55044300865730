@import '../App.scss';

#documents {
  #title {
    height: 300px;
    width: 100%;
    background-image: url('https://croner.com/wp-content/uploads/2022/02/page_banner_contact_standin.jpg');
    background-position: center -60px;
    background-color: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
    background-size: cover;

    h1 {
      text-transform: uppercase;
      font-size: 55px;
      line-height: normal;
      font-weight: 800;
      letter-spacing: 3px;
      color: #fff;
      text-align: center;
      padding-top: 118px;
    }
  }

  #planning_meeting,
  #questionnaire,
  #submit_documents,
  #survey_resources {
    margin: 60px 80px;
    color: #151b2c;

    h4 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 2px;
      text-align: center;
    }

    hr {
      max-width: 180px;
      height: 1px;
      background: #6d0f1d;
      margin-top: 22px;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;
      font-size: 18px;
    }

    #upload_area {
      border: 1px solid #d2d2d2;
      padding: 35px 20px 25px 20px;
      text-align: center;
      color: #151b2c;

      .dropzone {
        border: 1px dotted #d2d2d2;
        margin: 15px;
        padding: 15px;

        h3 {
          color: #203c74;
          // text-transform: uppercase;
  
          font-size: 28px;
          line-height: 1.05;
          font-weight: 500;
          letter-spacing: 0.2px;
          padding: 0 14px 14px;
        }
  
        .fa-cloud-upload-alt {
          font-size: 80px;
          color: #2f60a5;
        }
  
        p {
          font-size: 18px;
        }
      }


      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

#upload-modal {
  text-align: center;
  font-size: 18px;

  .files-accepted {
    p {
      color: $croner-green;
      margin: 15px 0;
    }

    i {
      font-size: 64px;
      color: $croner-green;
      margin-top: 15px;
    }
  }

  .files-rejected {
    p {
      color: $croner-yellow;
      margin: 15px 0;
    }

    i {
      font-size: 64px;
      color: $croner-yellow;
      margin-top: 15px;
    }
  }

  .files-virus {
    p {
      color: $croner-red;
      margin: 15px 0;
    }

    i {
      font-size: 64px;
      color: $croner-red;
      margin-top: 15px;
    }
  }
}
