@import '../App.scss';

#account-setup {
  #title {
    height: 307px;
    width: 100%;
    background-image: url('https://croner.com/wp-content/uploads/2022/04/page-banner-news.jpg');
    // background-position: center -60px;
    // background-color: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
    background-size: cover;
    // background-size: 116%;
    background-position: center center;
    background-repeat: no-repeat;

    h1 {
      text-transform: uppercase;
      font-size: 55px;
      line-height: normal;
      font-weight: 800;
      letter-spacing: 3px;
      color: #fff;
      text-align: center;
      padding-top: 118px;
    }
  }

  #account-setup-content {
    margin: 60px 80px;

    hr {
      max-width: 180px;
      height: 1px;
      background: #6d0f1d;
      // margin-left: 0;
      margin-top: 22px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
    }

    h4 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 2px;
      text-align: center;
    }

    #password-criteria {
      p {
        // text-align: center;
        font-size: 18px;
      }

      ul {
        list-style: none;
        // text-align: center;
        padding-left: 0;
        margin-bottom: 0;
        font-size: 18px;
      }

      .success {
        color: #24985b;
      }

      .error {
        color: #c92035;
      }
    }

    .error_sq {
      visibility: hidden;
      margin: 0;
    }

    .error_sq.show {
      visibility: visible;
      color: #c92035;
      // text-align: center;
      padding-bottom: 10px;
      font-size: 18px;
    }

    input {
      height: 50px;
      font-size: 16px;
      background-color: #fff;
      padding: 0px 15px;
      width: 100%;
      border-radius: 0;
      margin-bottom: 16px;
    }

    select {
      margin: auto;
      height: 50px;
      font-size: 16px;
      background-color: #fff;
      padding: 0px 15px;
      border-radius: 0;
      border-color: #d2d2d2;
      box-shadow: none;
      margin-bottom: 16px;
    }

    .form-control:focus {
      border-color: #d2d2d2;
      box-shadow: none;
    }

    #nda {
      width: 100%;
      height: 500px;
      overflow-y: scroll;
      background-color: white;
      border: 1px solid #eaedf0;
      margin-bottom: 16px;

      p {
        margin: 10px 15px 10px 15px;
        font-size: 16px !important;
      }
    }

    #nda-read {
      label {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        text-indent: -15px;
      }

      input {
        width: 13px;
        height: 13px;
        padding: 0;
        margin: 0;
        vertical-align: top;
        position: relative;
        top: 5px;
        *overflow: hidden;
      }
    }

    .nda-confirm {
      text-align: center;
      // margin-bottom: 16px;
      // color: $croner-red;
      font-weight: bold;
      font-size: 16px;
      padding-left: 10px;

      // input[type=checkbox] {
      //     position: relative;
      //     float: none;
      //     margin-right: 5px;
      // }

      &.read {
        color: $croner-green;
      }

      p {
        margin-bottom: 0px;
      }
    }
  }
}

.login,
.new-account,
.recover-username,
.reset-password,
.security-questions {
  background-image: url('https://croner.com/wp-content/uploads/2022/04/page-banner-news.jpg');
  background-size: 116%;
  background-position: center top;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  // max-width: 860px;
  // min-height: 381px;
  // color: #5f5f60;

  // hr {
  //     margin-top: 0;
  // }

  button {
    width: 100%;
  }

  a {
    color: $croner-blue;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }

  h4,
  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  li {
    font-size: 12px;
  }

  .form-group {
    margin-left: 15px;
    margin-right: 15px;
    border-color: #d2d2d2 !important;
    background-color: #fff !important;
  }

  .form-control {
    border-color: #d2d2d2 !important;
    background-color: #fff !important;
  }

  .form-control:focus {
    border-color: #d2d2d2 !important;
    background-color: #fff !important;
    box-shadow: none !important;
  }

  .form-control:active {
    border-color: #d2d2d2 !important;
    background-color: #fff !important;
  }

  input {
    height: 50px;
    font-size: 16px;
    background-color: #fff;
    padding: 0px 15px;
    width: 100%;
    border-radius: 0;
  }

  // select {
  //     font-size: 14px;
  // }

  form {
    @media all and (min-width: 1055px) {
      padding: 30px 0 25px 0;
      max-width: 700px;
      margin: 0 auto;
    }

    @media all and (max-width: 1054px) {
      padding: 0;
    }

    .custom-field-spacing {
      padding-top: 45px;
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 15px;
      // background-color: rgba(255, 255, 255, 0.5);
      border-radius: 0;
      border-width: 0;
    }

    .signin {
      height: 100%;
      list-style-type: none;
      font-size: 14px;
      padding-left: 15px;
      margin-top: -10px;
      // margin-bottom: 0px;
    }

    .text-danger {
      color: $croner-red !important;
    }
  }

  .user-type {
    color: $croner-red;
  }

  .user-title {
    margin-bottom: 16px;
  }

  .set-password {
    .form-group {
      margin-bottom: 0;
    }

    margin: 0 15px 0 15px;

    input,
    select {
      margin-bottom: 15px;
    }
    button {
      width: 100%;
      background-color: $croner-blue;
      // margin-bottom: 15px;
    }

    hr {
      margin-top: 0;
      margin-bottom: 15px;
    }

    ul {
      margin-bottom: 0;
      list-style-type: none;
      padding: 5px 15px 0 2px;
      margin-bottom: 15px;
    }

    li {
      line-height: 15px;
      margin-bottom: 5px;
      color: #5f5f60;
    }

    i {
      font-size: 14px;
    }

    .success {
      color: $croner-green !important;
    }

    .error {
      color: $croner-red !important;
    }

    p {
      margin-bottom: 0;
    }
  }

  .setup-user-password {
    .form-group {
      margin-bottom: 0;
    }

    // margin: 0 15px 0 15px;

    input,
    select {
      margin-bottom: 15px;
    }

    ul {
      margin-bottom: 0;
      list-style-type: none;
      padding: 5px 15px 0 2px;
      margin-bottom: 15px;
    }

    li {
      line-height: 15px;
      margin-bottom: 5px;
      color: #5f5f60;
    }

    i {
      font-size: 14px;
    }

    .success {
      color: $croner-green !important;
    }

    .error {
      color: $croner-red !important;
    }

    p {
      margin-bottom: 0;
    }
  }
}

// SASS MIXINS BEING //
@mixin croner-box($color, $url) {
  background-color: $color;
  background-image: url($url);
  background-position: right bottom;
  background-repeat: no-repeat;
  padding: 15px;
}

.popover {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  max-width: 100%;
}

@media all and (max-width: 575px) {
  .bottom-gap {
    margin-bottom: 15px;
  }
}

.welcome-modal {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 825px;

  img {
    padding-bottom: 20px;
    display: block;
    margin: 0 auto;
  }

  .btn {
    font-size: 14px;
    width: 100%;
  }

  .user-type {
    color: $croner-red;
  }

  p {
    font-size: 14px;
  }

  a {
    color: $croner-blue;
    font-weight: bold;
  }
}
