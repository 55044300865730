@import '../App.scss';

#results {
  #title {
    height: 300px;
    width: 100%;
    background-image: url('https:////croner.com/wp-content/uploads/2022/06/Croner_Consulting_Banner3.jpg');
    background-size: cover;
    background-color: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
    background-size: 100%;

    h1 {
      text-transform: uppercase;
      font-size: 55px;
      line-height: normal;
      font-weight: 800;
      letter-spacing: 3px;
      color: #fff;
      text-align: center;
      padding-top: 118px;
    }
  }

  #results_documents,
  #query {
    margin: 60px 80px;

    h4 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 2px;
      text-align: center;
    }

    hr {
      max-width: 180px;
      height: 1px;
      background: #6d0f1d;
      margin-top: 22px;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;
      font-size: 18px;
    }

    #query-parameters {
      border: 1px solid #d2d2d2;
      padding: 35px 20px 25px 20px;
      text-align: center;
      color: #151b2c;

      h5 {
        margin-bottom: 16px;
      }

      input {
        height: 50px;
        font-size: 16px;
        background-color: #fff;
        padding: 0px 15px;
        width: 100%;
        border-radius: 0;
        margin-bottom: 5px;
      }

      select {
        margin: auto;
        height: 50px;
        font-size: 16px;
        background-color: #fff;
        padding: 0px 15px;
        border-radius: 0;
        border-color: #d2d2d2;
        box-shadow: none;
        margin-bottom: 16px;
      }

      .form-control:focus {
        border-color: #d2d2d2;
        box-shadow: none;
      }

      button {
        width: 100%;
      }
    }
  }
}

.results-modal {
  @media all and (max-width: 1250px) {
    max-width: 95%;
  }
  max-width: 1200px;

  .modal-content {
    .modal-body {
      padding-bottom: 0;
      margin-left: 13px;
      margin-right: 13px;

      .for-print {
        display: none;
      }

      .results-bq-cuts {
        width: 100%;

        // display: table;
        // flex: wrap;

        .col {
          // white-space: normal;
          padding: 2px;
          flex-basis: calc(100% / 8) !important;
        }

        .cuts-btn {
          font-size: 11px;
          padding: 3px;
          width: 100%;
          box-shadow: none;
          flex-basis: 100%;
          flex: 1;
          border-radius: 0;
        }

        .cuts-btn-active {
          font-size: 11px;
          padding: 3px;
          width: 100%;
          background-color: $croner-blue;
          box-shadow: none;
          flex-basis: 100%;
          flex: 1;
          border-radius: 0;
        }

        h5 {
          text-align: left;
        }

        hr {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      /* Related to the results page and the various sections of the query */
      .bq-results {
        // white-space: pre-line;
        // overflow-x: hidden;
        font-family: 'Tahoma', sans-serif;

        h4 {
          text-align: center !important;
          font-weight: bold;
        }

        p {
          margin-top: 10px;
        }

        .col {
          padding: 2px;
        }

        .cuts-btn {
          font-size: 11px;
          padding: 3px;
          width: 100%;
          box-shadow: none;
          border-radius: 0;
        }

        a {
          display: inline-block;
          color: white !important;
          text-decoration: none !important;
          width: 100%;
          height: 100%;
        }

        .bq-header {
          border-bottom: 1px solid black;
        }

        .position-title {
          font-size: 13px;
          font-weight: bold;
        }

        .position-title-print {
          display: none;
        }

        #position-desc {
          white-space: pre-line;
          font-size: 11px;
        }

        .section-heading {
          font-size: 13px;
          text-align: center;
          font-weight: bold;
        }

        .section-heading-break {
          display: none;
        }

        .table-section-heading {
          font-weight: bold;
          text-align: left !important;
          padding-top: 2px !important;
          padding-bottom: 2px !important;
          background-color: #d9d9d9;
          border: 1px solid black !important;
        }

        table {
          margin-bottom: 12px;
        }

        tbody {
          border-top: none !important;
          border-right: 1px solid black !important;
        }

        th {
          background-color: #d9d9d9;
          border: 1px solid black !important;
          font-size: 11px;
          text-align: center;
          vertical-align: middle !important;
          padding-top: 2px !important;
          padding-bottom: 2px !important;

          i {
            font-size: 11px;
            padding-left: 2px;
          }
        }

        td {
          font-size: 11px;
          text-align: center;
          vertical-align: middle !important;
          border-top: none !important;
          padding: 2px;
        }

        .empty-td {
          display: none !important;
        }

        .bq-column-border {
          border-left: 1px solid black !important;
          border-right: 1px solid black !important;
          text-align: left;
        }

        .bq-bottom-border {
          border-bottom: 1px solid black !important;
        }

        .bq-left-border {
          border-left: 1px solid black !important;
        }

        .bq-right-border {
          border-right: 1px solid black !important;
        }

        .list-inline {
          // border: 1px solid black;
          // background-color: #d9d9d9;
          text-align: center;
          display: flex;

          li {
            flex: 1;
            font-weight: bold;
          }
        }

        .no-data {
          background-color: #d9d9d9;
        }
      }
    }
  }
}

#download-ready-popover {
  border-color: $croner-green;

  p {
    text-align: center;
    color: $croner-green;
    font-weight: bold;

    i {
      cursor: pointer;
      margin-top: 16px;
      font-size: 64px;
    }
  }
}
