@import '../../App.scss';

#cookie-law-info-bar {
  border: 0;
  font-size: 15px;
  margin: 0 auto;
  padding: 15px 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 9999;
  display: block;
  background-color: rgb(255, 255, 255);
  color: #333333;
  font-family: inherit;
  bottom: 0px;
  // border-top: 2px solid rgb(126, 128, 131);
	box-shadow: 0 -1px 10px 0 rgb(172 171 171 / 30%);
  position: fixed;
  transform: translateY(100vh);
  transition: transform 0.5s ease-in-out;
}

#cookie-law-info-bar.show {
  transform: translateY(0vh);
  transition: transform 0.5s ease-in-out;
}

#cookie-law-info-bar span {
  vertical-align: middle;
}
/** Buttons (http://papermashup.com/demos/css-buttons) */
.cli-plugin-button,
.cli-plugin-button:visited {
	font-size: 13px;
  display: inline-block;
  padding: 9px 12px;
  color: #fff;
  text-decoration: none;
  // -moz-border-radius: 4px; 
  // -webkit-border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  background-color: rgb(97, 162, 41);
}
.cli-plugin-button:hover {
  background-color: rgb(78, 130, 33);
  color: #fff;
  text-decoration: none;
}
.cli-plugin-button:active {
  top: 1px;
}
.cli-plugin-main-link {
  margin-left: 10px;
  color: #444;
	text-decoration: underline;
	font-weight: 600;
	font-size: 15px;
}
.small.cli-plugin-button,
.small.cli-plugin-button:visited {
  font-size: 11px;
}
.cli-plugin-button,
.cli-plugin-button:visited,
.medium.cli-plugin-button,
.medium.cli-plugin-button:visited {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
}
.large.cli-plugin-button,
.large.cli-plugin-button:visited {
  font-size: 14px;
  padding: 8px 14px 9px;
}
.super.cli-plugin-button,
.super.cli-plugin-button:visited {
  font-size: 34px;
  padding: 8px 14px 9px;
}
.pink.cli-plugin-button,
.magenta.cli-plugin-button:visited {
  background-color: #e22092;
}
.pink.cli-plugin-button:hover {
  background-color: #c81e82;
}
.green.cli-plugin-button,
.green.cli-plugin-button:visited {
  background-color: #91bd09;
}
.green.cli-plugin-button:hover {
  background-color: #749a02;
}
.red.cli-plugin-button,
.red.cli-plugin-button:visited {
  background-color: #e62727;
}
.red.cli-plugin-button:hover {
  background-color: #cf2525;
}
.orange.cli-plugin-button,
.orange.cli-plugin-button:visited {
  background-color: #ff5c00;
}
.orange.cli-plugin-button:hover {
  background-color: #d45500;
}
.blue.cli-plugin-button,
.blue.cli-plugin-button:visited {
  background-color: #2981e4;
}
.blue.cli-plugin-button:hover {
  background-color: #2575cf;
}
.yellow.cli-plugin-button,
.yellow.cli-plugin-button:visited {
  background-color: #ffb515;
}
.yellow.cli-plugin-button:hover {
  background-color: #fc9200;
}
