#account {
  #title {
    height: 300px;
    width: 100%;
    background-image: url('//croner.com/wp-content/uploads/2022/06/Survey_Banner_3-scaled.jpg');
    background-size: cover;
    background-position: center;
    background-color: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
    // background-size: auto auto;

    h1 {
      text-transform: uppercase;
      font-size: 55px;
      line-height: normal;
      font-weight: 800;
      letter-spacing: 3px;
      color: #fff;
      text-align: center;
      padding-top: 118px;
    }
  }

  #general_info,
  #update_password,
  #update_sq,
  #org_contacts,
  #active_sessions {
    margin: 60px 80px;

    hr {
      max-width: 180px;
      height: 1px;
      background: #6d0f1d;
      // margin-left: 0;
      // margin-top: 22px;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 2px;
      text-align: center;
    }

    #password-criteria {
      p {
        // text-align: center;
        font-size: 18px;
      }

      ul {
        list-style: none;
        // text-align: center;
        padding-left: 0;
        margin-bottom: 0;
        font-size: 18px;

      }

      .success {
        color: #24985b;
      }

      .error {
        color: #c92035;
      }
    }

    .error_sq {
      visibility: hidden;
      margin: 0;
    }

    .error_sq.show {
      visibility: visible;
      color: #c92035;
      // text-align: center;
      padding-bottom: 10px;
      font-size: 18px;

    }

    input {
      height: 50px;
      font-size: 16px;
      background-color: #fff;
      padding: 0px 15px;
      width: 100%;
      border-radius: 0;
      margin-bottom: 16px;
    }

    select {
      margin: auto;
      height: 50px;
      font-size: 16px;
      background-color: #fff;
      padding: 0px 15px;
      border-radius: 0;
      border-color: #d2d2d2;
      box-shadow: none;
      margin-bottom: 16px;
    }

    .form-control:focus {
      border-color: #d2d2d2;
      box-shadow: none;
    }
  }
}