@import '../../App.scss';

#messages {
    z-index: 1000;
    // position: fixed;
    width: 100%;
    font-size: 18px;

    // .container {
    //     max-width: 890px;

    //     @media (max-width: 992px) and (min-width: 768px) {
    //         max-width: 720px;

    //     }

    //     @media (max-width: 767px) and (min-width: 576px) {
    //         max-width: 510px;
    //         padding-left: 0px;
    //         padding-right: 0px;
    //     }
    // }

    a {
        color: #FFF;
        font-weight: bold;
    }

    i {
        cursor: pointer;
        padding-top: 3px;
    }

    // .message-text {
    //     width: 95%;
    // }

    // background-color: $croner-red;        
    // color: #FFF;
    .hide-messages-bar {
        color: #FFF;
        position: relative; 
        // top: 105px;
        // transform: translateY(-22.3px);
        transition: width 2s;   
        // box-shadow: 0 1px 6px 2px #333     
        // animation: fadeinout 2s linear 1 forwards;   
    }

    .hide-messages-bar.show-success-messages-bar {
        background-color: $croner-green;                
        // transform: translateY(0vh);
        transition: width 2s;   
        padding: 16px;
    }

    .hide-messages-bar.show-error-messages-bar {
        background-color: $croner-red;                
        // transform: translateY(0vh);
        transition: width 2s;   
        padding: 16px;

    }
}

// #error-messages {
//     position: relative; 
//     top: 105px;
//     background-color: $croner-red;        
//     color: #FFF;

//     a {
//         color: #FFF;
//         font-weight: bold;
//     }

//     .hide-error-messages-bar {
//         visibility: hidden;
//         height: 0;
//         transition: height 0.5s ease-out;        
//     }

//     .hide-error-messages-bar.show-error-messages-bar {
//         visibility: visible;
//         transition: height 0.5s ease-in;
//         height: 65px;
//         width: 100%;
//     }

//     .hide-error-message {
//     }

//     .hide-error-message.show-error-message {
//         padding: 8px 0;
//     }
// }



    
// #error-messages {

//     // background-color: $croner-red;        
//     // color: #FFF;
//     .hide-error-message {
//         background-color: $croner-red;        
//         color: #FFF;
//         position: relative; 
//         top: 105px;
//         transform: translateY(0vh);
//         transition: transform 0.5s ease-in;
//     }

//     .hide-error-message.show-error-messages-bar {
//         transform: translateY(25vh);
//         padding: 8px 0;
//     }
// }
    