@import "../../App.scss";

footer {
  // position: absolute;
  // bottom: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #151b2c;
  color: #fff;
  padding-top: 1px;

  #footer-content {
    max-width: 1300px;
    margin: 60px auto;

    .col-lg-4 {
      padding: 10px 50px;

      &.middle-col {
        padding-left: 80px;
      }

      h4 {
        font-size: 22px;
        line-height: 1;
        margin-bottom: 12px;
        font-weight: 800;
        letter-spacing: 4px;
      }

      p {
        max-width: 320px;
        margin-bottom: 4px;
        line-height: 1.6;
        // font-size: 16px !important;
      }

      a {
        color: #fff;
      }

      a:hover {
        color: #a61d35;
      }

      @media (max-width: 992px) {
        text-align: center;
        padding-bottom: 20px;

        h4 {
          font-size: 22px;
          text-align: center;
          line-height: 1;
          margin-bottom: 12px;
          font-weight: 800;
          letter-spacing: 4px;
        }

        p {
          max-width: none;
          text-align: center;
          margin-bottom: 4px;
          // margin: 15px;
        }

        &.middle-col {
          padding-left: 50px;
        }
      }

      // @media (max-width: 900px) {
      //   margin-bottom: 60px;

      //   h4 {
      //     font-size: 22px;
      //     text-align: center;
      //     line-height: 1;
      //     margin-bottom: 12px;
      //     font-weight: 800;
      //     letter-spacing: 4px;
      //   }

      //   p {
      //     max-width: none;
      //     text-align: center;
      //     margin-bottom: 4px;
      //     // margin: 15px;
      //   }
      // }
    }
  }

  .bottom-bar {
    height: 8px;
    background-image: linear-gradient(to right, #203c74, #2f60a5);
  }
}
