@import "../../App.scss";

#survey-select {
  margin: 60px 80px;

  #survey-select-header {
    h1 {
      text-transform: uppercase;
      font-size: 45px;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 1.5px;
      color: #151b2c;
      margin-bottom: 0;
      margin-top: 20px;
      text-align: center;
    }
  }

  #survey-select-body {
    hr {
      max-width: 180px;
      height: 1px;
      background: #6d0f1d;
      margin-top: 8px;
      margin-bottom: 25px;
    }

    select {
      margin: auto;
      height: 50px;
      font-size: 16px;
      background-color: #fff;
      padding: 0px 15px;
      width: 70%;
      border-radius: 0;
      border-color: #d2d2d2;
      box-shadow: none;

      .form-control:focus {
        border-color: #d2d2d2;
        box-shadow: none;
      }
    }

    select:focus {
      border-color: #d2d2d2;
      box-shadow: none;
    }

    h3 {
      color: #203c74;
      font-size: 28px;
      line-height: 1.05;
      font-weight: 500;
      letter-spacing: 0.2px;
      text-align: center;
    }
  }
}
