@import "../../App.scss";

.loader-container {
	width: 100%;
	height: 1200px;
	background: white;

	.loader {
		padding: 20px;
		height: 250px;
		width: 250px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

