// @import 'declarations';

#contact{
    #title {
        height: 300px;
        width: 100%;
        background-image: url("//croner.com/wp-content/uploads/2022/06/Croner_Contact_Banner1.jpg");
        background-position: center;
        background-color: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
        background-size: cover;
        background-repeat: no-repeat;

        @media all and (max-width: 1215px) {
          background-size: cover;
          background-repeat: no-repeat !important;

          // background-position-y: center;
        }

        h1 {
          text-transform: uppercase;
          font-size: 55px;
          line-height: normal;
          font-weight: 800; 
          letter-spacing: 3px;
          color: #fff;
          text-align: center;
          padding-top: 118px;
        }
      }

    #contact_form, #faq {
      margin: 60px 80px;

      hr {
        max-width: 180px;
        height: 1px;
        background: #6d0f1d;
        // margin-left: 0;
        margin-top: 22px;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 30px;
        text-transform: uppercase;
        line-height: 1.05;
        font-weight: 800;
        letter-spacing: 2px;
        text-align: center;
      }

      p {
        font-size: 18px;
      }

      input {
        height: 50px;
        font-size: 16px;
        background-color: #fff;
        padding: 0px 15px;
        width: 100%;
        border-radius: 0;
        margin-bottom: 16px;
      }
  
      textarea {
        font-size: 16px;
        background-color: #fff;
        padding: 12px 15px;
        width: 100%;
        border-radius: 0;
        margin-bottom: 16px; 
      }

      select {
        margin: auto;
        height: 50px;
        font-size: 16px;
        background-color: #fff;
        padding: 0px 15px;
        border-radius: 0;
        border-color: #d2d2d2;
        box-shadow: none;
        margin-bottom: 16px;
      }
  
      .form-control:focus {
        border-color: #d2d2d2;
        box-shadow: none;
      }
    }

    // .contact-custom-background {
    //     background-image: url('https://res.cloudinary.com/croner/image/upload/v1529001221/bg_second_red.gif');
    //     background-color: $croner-red;
    //     background-repeat: no-repeat;
    //     background-position: 60% 105px;
    //     background-attachment: fixed;
    // }

    // .contact-header {
    //     height: 90px;
    //     color: #FFF;
    //     padding-top: 20px;
        
    //     @media all and (max-width: 575px) {
    //         text-align: center;
    //     }
    // }

    // .contact-custom-spacing {
    //     padding-top: 105px;    

    //     @media all and (max-width: 575px) {
    //         padding-left: 0;
    //         padding-right: 0;
    //     }
    // }

    // .contact-sections {
    //     color: $dark-gray-font;
        
    //     .contact-section-headers {
    //         // background: -webkit-gradient(linear, left top, right bottom, from($croner-red), to(#FFFFFF));
    //         background-color: $croner-red;
    //         background-image: url('https://res.cloudinary.com/croner/image/upload/v1529001221/bg_second_red.gif');
    //         background-position: right bottom;
    //         background-size: contain;
    //         background-repeat: no-repeat; 
    //         color: #FFFFFF;
    //     }

    //     .form-group {
    //         margin: 10px;
            
    //         input {
    //             margin-bottom: 10px;
    //         }

    //         button {
    //             width: 100%;
    //             background-color: $croner-red;
    //         }
    //     }

    //     .form-control {
    //         font-size: 14px;
    //     }

    //     a {
    //         color: $croner-red;
    //         font-weight: bold;
    //         font-size: 14px;
    //     }

    //     h4 {
    //         margin: 10px;
    //         font-size: 16px;
    //         font-weight: bold;
    //     }

    //     hr {
    //         margin-top: 0;
    //         margin-bottom: 0;
    //     }

    //     p {
    //         margin: 10px;
    //         font-size: 14px;
    //     }

    //     .arrow-down {
	// 		transform: rotate(90deg);
	// 		transition: transform 150ms ease;
	// 	}

	// 	.arrow-up {
	// 		transform: rotate(-90deg);
	// 		transition: transform 150ms ease;
	// 	}
    // }
}