// @import 'declarations';

#participation {
  #title {
    height: 300px;
    width: 100%;
    background-image: url('//croner.com/wp-content/uploads/2022/05/Hero_CSF_Blue.jpg');
    background-position: center;
    background-color: linear-gradient(25deg, #001d75 25%, #2f60a5 100%);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;

    @media all and (max-width: 1215px) {
      background-size: cover;
      background-repeat: no-repeat !important;

      // background-position-y: center;
    }

    h1 {
      text-transform: uppercase;
      font-size: 55px;
      line-height: normal;
      font-weight: 800;
      letter-spacing: 3px;
      color: #fff;
      text-align: center;
      margin: auto;
      align-self: center; /* Vertically center the content */
    }
  }

  #participation-content {
    margin: 60px 80px;

    hr {
      max-width: 180px;
      height: 2px;
      background: #6d0f1d;
      // margin-left: 0;
      margin-top: 22px;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 1.05;
      font-weight: 800;
      letter-spacing: 2px;
      text-align: center;
    }

    p,
    li,
    table {
      font-size: 18px;
    }

    #participation-tiers {
      text-align: center;
      margin: 15px 0px;

      thead {
        th {
          border: none;
        }

        th:not(:empty) {
          width: 50px;
        }
      }

      td,
      th {
        padding: 10px 30px;
        border: black 1px solid;
      }

      .participation-col-1 {
        background: rgb(52, 95, 162);
        color: white;
      }

      .participation-col-2 {
        color: rgb(187, 145, 32);
        font-weight: bold;
      }

      .participation-col-3 {
        color: rgb(52, 95, 162);
        font-weight: bold;
      }

      .participation-col-4 {
        color: rgb(80, 116, 51);
        font-weight: bold;

        &:empty {
          background: rgb(173, 185, 202);
        }
      }
    }

    #participation-surveys {
      margin: 15px 0;
      text-align: center;

      table {
        width: 100%;
      }
      td,
      th {
        padding: 10px 10px;
        border: black 1px solid;
      }
      thead {
        th {
          background: lightgray;
        }
      }
      tbody {
        tr {
          .survey-description {
            text-align: left;
          }
        }
      }
    }

    #participation-form {
      // padding: 15px 0;

      input {
        height: 50px;
        font-size: 16px;
        background-color: #fff;
        padding: 0px 15px;
        width: 100%;
        border-radius: 0;
        margin-bottom: 30px;
      }

      textarea {
        font-size: 16px;
        background-color: #fff;
        padding: 12px 15px;
        width: 100%;
        border-radius: 0;
        margin-bottom: 16px;
      }

      select {
        // margin: auto;
        height: 50px;
        font-size: 16px;
        background-color: #fff;
        padding: 0px 15px;
        border-radius: 0;
        border-color: #d2d2d2;
        box-shadow: none;
        // margin-bottom: 16px;
      }

      .form-control:focus {
        border-color: #d2d2d2;
        box-shadow: none;
      }
    }

    #participation-dates {
      margin: 15px 0px;

      td {
        padding: 0px 20px;
      }
    }

    .card {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
      padding: 15px;

      .card-title {
        font-weight: bold;
        font-size: 20px;
        text-transform: uppercase;
      }

      .card-subtitle {
        margin-bottom: auto;
        padding-bottom: 15px;
      }

      button {
        margin-top: auto;
        border: none;
        width: 100%;
      }
    }
  }
}

#participation-confirmation-table {
  width: 100%;
  border-collapse: collapse;
  tr {
    td:first-child {
      width: 100%;
    }
    td:nth-child(2) {
      min-width: 120px;
    }
  }

  td {
    padding: 5px 15px;
  }
}
